import React, { useContext, useState } from "react";
import {
  Button,
  Divider,
  ListItem,
  ListItemText,
  List,
  Typography,
  ListItemAvatar,
  Alert,
} from "@mui/material";
import { ContentContext } from "../../layout";
import Navbar from "../../navbar";
import { OrderAppartmentContext } from "../../../context/OrderContext";
import axios from "axios";
import { API_URL } from "../../../utils/constants";
import { toast } from "react-toastify";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import {
  CalendarTodayRounded,
  HouseOutlined,
  LocalPhone,
} from "@mui/icons-material";
import Box from "@mui/material/Box";
import Lazy from "../../lazyAnimation";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const OrderApartmentSummary = () => {
  const { translate } = useContext(ContentContext);
  const {
    selectedDates,
    userInfo,
    price,
    selectedAppartment,
    setUserInfo,
    setSelectedDates,
    setCancelSelectedDates,
    setPrice,
    setSelectRange,
    setSelectedApartment,
    setVisibleSection,
    cancelSelectedDates,
  } = useContext(OrderAppartmentContext);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const onSubmit = async () => {
    const CAMEROON_COUNTRY_CODE = "+237";
    setIsSubmitting(true);
    const data = {
      days: selectedDates,
      userName: userInfo.name,
      userPhoneNumber: CAMEROON_COUNTRY_CODE + userInfo.phoneNumber,
      apartment: selectedAppartment,
      total: price,

      startDate: selectedDates[0],
      endDate: selectedDates[selectedDates.length - 1],
      language:
        typeof window !== "undefined"
          ? localStorage.getItem("defaultLanguage")
          : null,
    };
    await axios
      .post(`${API_URL}/getApartmentDays`, {
        apartNumber: selectedAppartment?.apartNumber,
        selectedDates: selectedDates,
      })
      .then((response) => {
        if (response.status === 200) {
          axios
            .post(`${API_URL}/bookApartment`, data)
            .then((r) => {
              if (r.status === 200) {
                // reset
                setSelectedApartment(null);
                setSelectedDates([]);
                setUserInfo({
                  name: "",
                  phoneNumber: "",
                });
                setPrice(0);
                setSelectRange(false);
                setIsSubmitting(false);
                setVisibleSection("successfullOrderPlaced");
              }
            })
            .catch((e) => {
              setIsSubmitting(false);
              if (e.response.status === 403) {
                toast.error(translate("user_exist"));
              } else {
                toast.error(translate("invalid_data"));
              }
            });
        }
      })
      .catch((error) => {
        setIsSubmitting(false);
        if (error.response.status === 402) {
          setCancelSelectedDates(true);
        }
      });
  };

  return (
    <>
      <div id="container-apart">
        <Lazy type='slide' delay={150}  timeout={1000} direction='left' className='slide-container'>
          <div className="frst">
            <div className="snd">
                <Box
                  id="apart"
                  sx={{
                    maxWidth: 400,
                    margin: "0 auto",
                    display: "flex",
                    flexDirection: "column",
                    p: 1,
                  }}
                >
                  <Navbar
                    title={`${translate("order_summary")}`}
                    previousPage="bookAnApartment"
                    varaint="apartment"
                  />
                  {cancelSelectedDates && (
                    <Alert severity="error" sx={{ marginBottom: "15px" }}>
                      <p
                        onClick={() => {
                          setVisibleSection("calculatePrice");
                          setSelectedDates([]);
                        }}
                      >
                        {translate(
                          "some_of_your_selected_dates_are_no_more_available"
                        )}
                      </p>
                    </Alert>
                  )}
                  <List
                    sx={{
                      width: "100%",
                      bgcolor: "background.paper",
                      borderRadius: "5px",
                      boxShadow: "0px 0px 4px #c5c2c2",
                      margin: 'auto',
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "0px 16px",
                      }}
                    >
                      <Typography
                        fontWeight={`bold`}
                        color="#302f2f"
                        sx={{ fontSize: "18px" }}
                      >
                        {translate("your_information")}
                      </Typography>
                      <Button
                        onClick={() => setVisibleSection("bookAnApartment")}
                        variant="contained"
                        className="primary"
                        size="small"
                        sx={{
                          height: 40,
                          fontSize: '16px',
                          borderRadius: "6px",
                          textTransform: "initial",
                          padding: '16px',
                          marginTop: '10px',
                        }}
                      >
                        {translate("change")}
                      </Button>
                    </Box>
                    <ListItem>
                      <ListItemAvatar>
                        <PermIdentityIcon />
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <Typography
                            color="#302f2f"
                            sx={{ fontSize: "16px", fontWeight: "bold" }}
                          >
                            {translate("name")}
                          </Typography>
                        }
                        secondary={
                          <Typography color="#302f2f" sx={{ fontSize: "14px" }}>
                            {userInfo.name}
                          </Typography>
                        }
                      />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                    <ListItem>
                      <ListItemAvatar>
                        <LocalPhone />
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <Typography
                            color="#302f2f"
                            sx={{ fontSize: "16px", fontWeight: "bold" }}
                          >
                            {translate("phone_number")}
                          </Typography>
                        }
                        secondary={
                          <Typography color="#302f2f" sx={{ fontSize: "14px" }}>
                            {userInfo.phoneNumber}
                          </Typography>
                        }
                      />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "0px 16px",
                        marginTop: 3,
                      }}
                    >
                      <Typography
                        fontWeight={`bold`}
                        color="#302f2f"
                        sx={{ fontSize: "18px" }}
                      >
                        {translate("apartment")}
                      </Typography>
                      <Button
                        onClick={() => setVisibleSection("calculatePrice")}
                        variant="contained"
                        className="primary"
                        size="small"
                        sx={{
                          height: 40,
                          fontSize: '16px',
                          borderRadius: "6px",
                          textTransform: "initial",
                          padding: '16px',
                        }}
                      >
                        {translate("change")}
                      </Button>
                    </Box>
                    <ListItem>
                      <ListItemAvatar>
                        <HouseOutlined />
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <Typography
                            color="#302f2f"
                            sx={{ fontSize: "16px", fontWeight: "bold" }}
                          >
                            {translate("apartment")}
                          </Typography>
                        }
                        secondary={
                          <Typography color="#302f2f" sx={{ fontSize: "14px" }}>
                            {selectedAppartment?.name}
                          </Typography>
                        }
                      />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                    <ListItem>
                      <ListItemAvatar>
                        <CalendarTodayRounded />
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <Typography
                            color="#302f2f"
                            sx={{ fontSize: "16px", fontWeight: "bold" }}
                          >
                            {translate("start_end_date")}
                          </Typography>
                        }
                        secondary={
                          <Typography
                            color="#302f2f"
                            sx={{ fontSize: "14px" }}
                          >{`${new Date(selectedDates[0])
                            .toDateString()
                            .split(" ")
                            .slice(1)
                            .join(" ")} ${translate("and")} ${new Date(
                            selectedDates[selectedDates.length - 1]
                          )
                            .toDateString()
                            .split(" ")
                            .slice(1)
                            .join(" ")}`}</Typography>
                        }
                      />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                  </List>
                  <Box sx={{ marginTop: 2 }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: `center`,
                        padding: "1rem",
                      }}
                    >
                      <Box>
                        <Typography
                          color="#302f2f"
                          sx={{ fontSize: "16px", fontWeight: "bold" }}
                        >
                          Total{" "}
                        </Typography>
                        <Typography
                          color="#302f2f"
                          sx={{ fontSize: "16px", fontWeight: "bold" }}
                        >
                          {translate("for")} {selectedDates.length}{" "}
                          {translate("day")}(s)
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          color="#302f2f"
                          sx={{ fontSize: "16px", fontWeight: "bold" }}
                        >
                          <Typography
                            color="#302f2f"
                            sx={{ fontSize: "16px", fontWeight: "bold" }}
                            marginRight={`4px`}
                          >
                            XAF
                          </Typography>
                          {new Intl.NumberFormat().format(price)}
                        </Typography>
                      </Box>
                    </Box>
                    <Button
                      fullWidth
                      variant="contained"
                      className="primary"
                      endIcon={<ArrowForwardIcon sx={{ color: "white" }} />}
                      sx={{
                        marginTop: 2,
                        height: 50,
                        borderRadius: "6px",
                        textTransform: "initial",
                        fontSize: "16px",
                        "&:disabled": {
                          "-webkit-text-fill-color": "#c6c6c6 !important",
                        },
                      }}
                      onClick={onSubmit}
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? (
                        <span style={{ color: "#fff!important" }}>
                          {translate("booking_in_progress")}
                        </span>
                      ) : (
                        translate("book_car")
                      )}
                    </Button>
                  </Box>
                </Box>
            </div>
          </div>
        </Lazy>
      </div>
    </>
  );
};

export default OrderApartmentSummary;
