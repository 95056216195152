import React, { useState } from "react";
import "../../index.css";
import "react-calendar/dist/Calendar.css";
import Layout from "../../components/layout";
import {
  Apartment,
  OrderAppartmentContext,
  UserInfo,
} from "../../context/OrderContext";
import ApartmentShowCase from "../../components/section/bookAnApartment/ApartmentShowCase";
import CalculateAparmentPriceForm from "../../components/section/bookAnApartment/CalculateAparmentPriceForm";
import BookAnApartmentForm from "../../components/section/bookAnApartment/bookAnApartmentForm";
import OrderApartmentSummary from "../../components/section/bookAnApartment/OrderApartmentSummary";
import SuccessFullOrder from "../../components/section/bookAnApartment/SuccessfullOrder";

const BookAnApartment = () => {
  const [visibleSectionState, setVisibleSectionAction] = useState<
    "index" | "calculatePrice" | "bookAnApartment" | "summary" | "successfullOrderPlaced"
  >("index");
  const [selectedApartmentState, setSelectedApartmentAction] =
    useState<Apartment | null>(null);
  const [selectedDates, setSelectedDates] = useState<Date[]>([]);
  const [cancelSelectedDates, setCancelSelectedDates] =
    useState<boolean>(false);
  const [userInfo, setUserInfo] = useState<UserInfo>({
    name: "",
    phoneNumber: "",
  });
  const [price, setPrice] = useState<number>(0);
  const [selectRange, setSelectRange] = useState<boolean>(false);
  const [disabledDates, setDisabledDates] = useState<string[]>([]);

  return (
    <Layout>
      <OrderAppartmentContext.Provider
        value={{
          selectedAppartment: selectedApartmentState,
          setSelectedApartment: setSelectedApartmentAction,
          visibleSection: visibleSectionState,
          setVisibleSection: setVisibleSectionAction,
          selectedDates,
          setSelectedDates,
          cancelSelectedDates,
          setCancelSelectedDates,
          userInfo,
          setUserInfo,
          price,
          setPrice,
          selectRange,
          setSelectRange,
          datesToBeDisabled: disabledDates,
          setDatesToBeDisabled: setDisabledDates,
        }}
      >
        {visibleSectionState === "index" && <ApartmentShowCase />}
        {visibleSectionState === "calculatePrice" && (
          <CalculateAparmentPriceForm />
        )}
        {visibleSectionState === "bookAnApartment" && <BookAnApartmentForm />}
        {visibleSectionState === "summary" && <OrderApartmentSummary />}
        {visibleSectionState === "successfullOrderPlaced" && (
          <SuccessFullOrder />
        )}
      </OrderAppartmentContext.Provider>
    </Layout>
  );
};

export default BookAnApartment;
