import { Box, Button, Typography } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { OrderAppartmentContext } from "../../context/OrderContext";
import { ContentContext } from "../layout";
import { LeftArrow, RightArrow } from "../../images";
import "./index.css";

interface Props {
  apartNumber: string,
  name: string,
  imageSrc: string[];
  address: string;
  price: number;
  variant?: string;
  offers?: {
    wifi: boolean,
    parking: boolean,
    dedicatedWokspace: boolean,
    courtYardView: boolean,
    nextflix: boolean,
  }
}

const enum VARIANT {
  primary = "primary",
  secondary = "primary"
}
const CarouselCard: React.FC<Props> = ({ imageSrc, address, price, variant, offers, name, apartNumber }) => {
  const [activeCarouselIndex, setActiveCarouselIndex] = useState<number>(0);
  const [totalCarouselItems, setTotalCarouselItems] = useState<number>(0);
  const carousel = useRef<any>(null);

  useEffect(() => {
    setTotalCarouselItems(carousel?.current?.childNodes?.length);
  }, [carousel?.current?.childNodes?.length]);

  const scrollLeft = (e: any) => {
    e.stopPropagation();
    carousel.current.scrollLeft -= carousel.current.clientWidth;
    setActiveCarouselIndex((index) => index - 1);
  };

  const scrollRight = (e: any) => {
    e.stopPropagation();
    carousel.current.scrollLeft += carousel.current.clientWidth;
    setActiveCarouselIndex((index) => index + 1);
  };

  const handleScroll = (e: any) => {
    const scrollWidth = e.currentTarget.scrollWidth;
    const scrollLeft = e.currentTarget.scrollLeft;
    const clientWidth = e.currentTarget.clientWidth;
    const arrows = e.currentTarget.nextElementSibling.children;
    if (scrollLeft === 0) {
      arrows[0].style.opacity = "0";
    } else {
      arrows[0].style.opacity = "1";
    }
    if (scrollWidth - scrollLeft === clientWidth) {
      arrows[1].style.opacity = "0";
    } else {
      arrows[1].style.opacity = "1";
    }
  };

  const {
    setSelectedApartment,
    setVisibleSection,
    selectedAppartment,
    visibleSection,
  } = useContext(OrderAppartmentContext);
  const { translate } = useContext(ContentContext);

  const scrollButtonStyle = {
    display: "inline-flex",
    borderRadius: "50%",
    border: "1px solid rgba(0, 0, 0, 0.08)",
    outline: "currentcolor none medium",
    margin: " 0px",
    padding: " 0px",
    color: "rgb(34, 34, 34)",
    backgroundColor: " rgba(255, 255, 255, 0.9)",
    cursor: "pointer",
    touchAction: "manipulation",
    alignItems: "center",
    justifyContent: "center",
    backgroundClip: "padding-box",
    boxShadow:
      "transparent 0px 0px 0px 1px, transparent 0px 0px 0px 4px, rgba(0, 0, 0, 0.18) 0px 2px 4px",
    transition:
      " -ms-transform 0.25s ease 0s, transform 0.25s ease 0s, transform 0.25s ease 0s",
    width: "32px",
    height: "32px",
  };

  const cardStyle = (active: boolean) => {
    return {
      position: "relative",
      background: active ? "#f2f2f2" : "transparent",
      padding: "0px 0px 16px 0px",
      borderRadius: 2,
      minHeight: "270px",
      boxShadow:
        "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
      cursor: "pointer",
      minWidth: "200px",
    };
  };
  const cardStylSecondary = {
    position: "relative",
    padding: "0px 0px 16px 0px",
    height: "300px",
    cursor: "pointer",
    display: "flex",
    overflowY: "clip",
    overflowX: "scroll",
    maxWidth: variant === VARIANT.secondary ? "370px" : "280px",
    scrollSnapType: "x mandatory",
    scrollBehavior: "smooth",
    borderTopLeftRadius: variant === VARIANT.secondary ? "0" : "8px",
    borderTopRightRadius: variant === VARIANT.secondary ? "0" : "8px",
    margin: "0 auto",
  };
  const style = () => {
    return {
      display: "flex",
      height: "200px",
      overflowY: "clip",
      overflowX: "scroll",
      maxWidth: variant === VARIANT.secondary ? "370px" : "100%",
      scrollSnapType: "x mandatory",
      scrollBehavior: "smooth",
      borderTopLeftRadius: variant === VARIANT.secondary ? "0" : "8px",
      borderTopRightRadius: variant === VARIANT.secondary ? "0" : "8px",
    };
  };

  return (
    <Box
      sx={variant !== VARIANT.secondary ? cardStyle(true) : cardStylSecondary}
      onClick={() => {
        setSelectedApartment({
          apartNumber,
          name,
          address: address,
          price: price,
          imageSrc: imageSrc,
          offers
        });
        setVisibleSection("calculatePrice");
      }}
    >
      <Box>
        <Box
          id="carousel"
          sx={
            variant !== VARIANT.secondary ?
              style
              :
              cardStylSecondary
          }
          className="hide-scroll-bar"
          ref={carousel}
          onScroll={handleScroll}
        >
          {imageSrc.map((image, idx) => (
            <Box
              key={idx}
              component={"img"}
              src={image}
              sx={{ scrollSnapAlign: "start", minWidth: "100%", height: `${variant !== VARIANT.secondary ? "auto" : "auto"}` }}
            ></Box>
          ))}
        </Box>
        <Box
          sx={{
            width: "90%",
            margin: "0 auto",
            height: "2.5rem",
            position: "absolute",
            left: "50%",
            top: "40%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {imageSrc.length > 1 && (
            <>
              <span
                style={{
                  ...scrollButtonStyle,
                  opacity: activeCarouselIndex > 0 ? 1 : 0,
                }}
                onClick={(e) => scrollLeft(e)}
              >
                <img height={"10px"} src={LeftArrow} />
              </span>
              <span
                style={{
                  ...scrollButtonStyle,
                  opacity:
                    activeCarouselIndex === totalCarouselItems - 1 ? 0 : 1,
                }}
                onClick={(e) => scrollRight(e)}
              >
                <img height={"10px"} src={RightArrow} />
              </span>
            </>
          )}
        </Box>
      </Box>
      {variant !== VARIANT.secondary && <Box>
        <Typography fontSize={".75rem"} fontWeight={200} pt={1} pl={1} pr={1}>
          {address}
        </Typography>
        <Typography fontSize="small" mt={1} pl={1} pr={1}>
          <span style={{ fontWeight: 700 }}>XAF {price}k</span>/
          {translate("night")}
        </Typography>
      </Box>}
    </Box>
  );
};

export default CarouselCard;
