import React, { useContext, useEffect, useState } from "react";
import { ContentContext } from "../../layout";
import {
  Avatar,
  IconButton,
  Menu,
  Stack,
  Tooltip,
  Typography,
  Icon
} from "@mui/material";
import CountryFlag from "../../countryFlag";
import { apartmentImages, CameroonFlag } from "../../../images";
import { OrderAppartmentContext } from "../../../context/OrderContext";
import { UserContext } from "../../../context/AuthContext";
import LoginPage from "../../login";
import CarouselCard from "../../carouselCard";
import { LogoWhite } from "../../../images";
import { Image } from 'mui-image';
import './style.css';
import { bgImage } from "../../../images";
import Box from '@mui/material/Box';
import NavbarBack from "../../navbarBack";
import { Link } from 'gatsby';
import Lazy from "../../lazyAnimation";
import Skeleton from '@mui/material/Skeleton';
import Loader from '../../Loader'





const ApartmentShowCase = (props: { loading?: boolean }) => {
  const { translate } = useContext(ContentContext);
  const { loading = false } = props;

  const avatarStyle = {
    width: "60px",
    height: "60px",
    cursor: "pointer",
    background: "#000",
    position: "relatve",
    overflow: "inherit",
  };
  const styles = {
    paperContainer: {
      backgroundImage: `url(${bgImage})`
    }
  };
  const slideStyle = {
    marginTop: "20px",
    overflowY: "scroll",
    paddingTop: "1px",
    marginRight: "-16px",
    paddingRight: "16px",
    msOverflowStyle: "none",
    scrollbarWidth: "none",
    paddingBottom: "1px",
  };
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openDropdownMenu = Boolean(anchorEl);

  const { user, isAuth, logOut } = useContext(UserContext);

  const { setSelectedDates } = useContext(OrderAppartmentContext);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setSelectedDates([]);
  }, []);
  const apartments = [
    {
      apartNumber: `${translate("ap_1")}`,
      name: `${translate("spacious_apartment_with_2_bed_room")}`,
      address: `${translate("odza_borne_10_yaoundé_cameroon")}`,
      imageUrl: [
        apartmentImages[2],
        apartmentImages[0],
        apartmentImages[1],
        apartmentImages[3],
        apartmentImages[4],
        apartmentImages[5],
        apartmentImages[6],
        apartmentImages[7],
        apartmentImages[8],
        apartmentImages[9],
        apartmentImages[10],
      ],
      price: 25,
      offers: {
        wifi: true,
        parking: true,
        dedicatedWokspace: true,
        courtYardView: true,
        nextflix: true,
      }
    },
  ];

  function stringAvatar(name: string | null) {
    if (!name) return "";
    const names = name.split(" ");
    return {
      children:
        names.length > 1
          ? `${names[0][0].toUpperCase()}${names[1][0].toUpperCase()}`
          : `${names[0][0]}`,
    };
  }

  return (
    <>
      {loading ? (
       <Loader />
      ) : (
        <div id='container-apart'>
          <Lazy type='slide' delay={150}  timeout={1000} direction='left' className='slide-container'>
            <div className="frst">
              <div className="snd">
                  <div id='apart'>
                    <Link to='/services'>
                      <NavbarBack title={translate('back_option')} />
                    </Link>
                    <Box
                      sx={{
                        padding: "12px 0 0",
                      }}
                    >
                      <Box sx={{ flexBasis: "65%" }}>
                        <Typography sx={{ fontWeight: "bold", fontSize: "1.1rem" }}>
                          {isAuth
                            ? translate("morning") + ` ${user.displayName?.split(" ")?.[0]}!`
                            : translate("welcome_to_bw")}
                        </Typography>
                        <Typography color='#302f2f' sx={{ fontSize: "14px" }}>
                          {translate("which_apartment_would_you_want_to_rent_today")}
                        </Typography>
                      </Box>
                    </Box>
                    <Box sx={{ marginTop: "40px" }}>
                      <Box display="flex" sx={{ columnGap: "2px", alignItems: "center" }}>
                        <Typography color='#302f2f' sx={{ fontWeight: "bold", fontSize: "0.85rem" }}>
                          {translate("our_apartments")}
                        </Typography>
                      </Box>
                      <Stack
                        direction="row"
                        spacing={3}
                        sx={slideStyle}
                        className="no__scroll"
                      >
                        {apartments.map((apartment, idx) => (
                          <CarouselCard
                            key={idx}
                            imageSrc={apartment?.imageUrl}
                            price={apartment?.price}
                            address={apartment.address}
                            offers={apartment.offers}
                            name={apartment.name}
                            apartNumber={apartment.apartNumber}
                          />
                        ))}
                      </Stack>
                    </Box>
                    {openLoginModal && (
                      <LoginPage
                        openLoginModal={openLoginModal}
                        setOpenLoginModal={setOpenLoginModal}
                      />
                    )}
                  </div>
              </div>
            </div>
          </Lazy>
        </div>
      )
      }
    </>
  );
};

export default ApartmentShowCase;
