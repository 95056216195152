import React, { useContext, useEffect, useRef, useState } from "react";
import {
    FormControlLabel,
    FormGroup,
    Modal,
    Stack,
    Switch,
    Typography,
    Divider,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText
} from "@mui/material";
import {
    Calendar,
    DateObject,
    getAllDatesInRange,
} from "react-multi-date-picker";
import "react-calendar/dist/Calendar.css";
import "./_index.css";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Navbar from "../../navbar";
import french_calendar from "../../../../locales/fr/calendar_fr";
import gregorian_en from "react-date-object/locales/gregorian_en";
import { Box } from "@mui/system";
import { OrderAppartmentContext } from "../../../context/OrderContext";
import { ContentContext } from "../../layout";
import axios from "axios";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { API_URL } from "../../../utils/constants";
import { Close } from "@mui/icons-material";
import WifiIcon from '@mui/icons-material/Wifi';
import VrpanoIcon from '@mui/icons-material/Vrpano';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import CarouselCard from "../../carouselCard";
import Lazy from "../../lazyAnimation";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Skeleton from '@mui/material/Skeleton';
import Loader from "../../Loader";




const inputStyles = {
    p: "2px 4px",
    display: "flex",
    alignItems: "center",
    background: "#f2f2f2",
    boxShadow: "none",
    borderRadius: "4px",
};

const arrayInputFieldContainer = {
    display: "flex",
    alignItems: "center",
    padding: "10px",
    boxShadow: "none",
    borderRadius: "4px",
};

const enum VARIANT {
    primary = "primary",
    secondary = "primary",
    car = "car",
    apartment = "apartment"
}

const CalculateAparmentPriceForm = () => {
    const ln_local =
        typeof window !== "undefined" ? localStorage.getItem("defaultLanguage") : null;

    const [calendarLang, setCalendarLang] = useState(gregorian_en);
    const { translate } = useContext(ContentContext);

    const style = {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        maxWidth: 400,
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 4,
        background: "#fff",
        width: "300px",
        borderRadius: "6px",
    };

    const CarouselStyle = {
        height: "300px"
    }

    const [limitError, setLimitError] = useState<boolean>(false);

    const {
        selectedAppartment,
        setVisibleSection,
        visibleSection,
        datesToBeDisabled,
        setSelectedDates,
        selectedDates,
        price,
        setPrice,
        selectRange,
        setSelectRange,
        setUserInfo,
        setSelectedApartment,
        setDatesToBeDisabled,
        cancelSelectedDates,
        setCancelSelectedDates,
    } = useContext(OrderAppartmentContext);

    const [loader, setLoader] = useState<boolean>(false)
    const [loadDisableDates, setLoadingDisableDates] = useState<boolean>(true);
    const [leadingZeroError, setLeadingZeroError] = useState<boolean>(false);
    const [chooseTwoDatesAndAboveError, setChooseChooseTwoDatesAndAboveError] =
        useState<boolean>(false);
    const [limitNumberOfDays, setLimitNumberOfDays] = useState<boolean>(false);

    const getBookedDays = () => {

        axios
            .post(`${API_URL}/getApartmentBookedDays`, { apartNumber: selectedAppartment?.apartNumber })
            .then((r) => {
                setDatesToBeDisabled(
                    r?.data[0]
                );
                setLoadingDisableDates(false);
            })
            .catch((e) => console.error(e));
    };

    useEffect(() => {
        cancelSelectedDates ? setSelectedDates([]) : null;
        cancelSelectedDates ? setCancelSelectedDates(false) : null;
    }, []);

    useEffect(() => {
        getBookedDays();
        return () => { };
    }, [selectedAppartment?.name]);


    const handleSelectDateRange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setSelectRange(event.target.checked);
        setSelectedDates([]);
    };


    const getDate = (selectedDates: DateObject | DateObject[] | null) => {

        if (selectedDates) {
            if (selectRange && !(selectedDates instanceof DateObject)) {
                const finale: Array<string> = [];
                const datesList = getAllDatesInRange(selectedDates).map(
                    (e) =>
                        (e instanceof Date ? e : e.toDate()).toISOString().split("T")[0]
                );
                datesList.map((d) => {
                    if (!datesToBeDisabled.includes(d)) {
                        finale.push(d);
                    }
                });
                setSelectedDates(finale.map((d) => new Date(d)));
                return;
            }
            if (selectedDates instanceof DateObject) {
                setSelectedDates([selectedDates.toDate()]);
            } else {
                setSelectedDates([...selectedDates.map((d) => d.toDate())]);
            }
        }

    };


    useEffect(() => {
        setCalendarLang(ln_local === "EN" ? gregorian_en : french_calendar);
        return () => { };
    }, []);

    useEffect(() => {
        if (selectedDates.length <= 30) {
            setLimitNumberOfDays(false);
            const price = (selectedAppartment?.price || 0) * 1000 * selectedDates.length;
            setPrice(price);

        } else if (selectedDates.length > 30) {
            setLimitNumberOfDays(true);
        }
    }, [selectedDates.length]);

    const resetForm = () => {
        if (
            typeof window !== "undefined" &&
            confirm(translate("reset_form_warning_message"))
        ) {

            setSelectedDates([]);
            setUserInfo({ name: "", phoneNumber: "" });
            setPrice(0);
            setSelectRange(false);
        }
    };

    const onSubmit = () => {
        setVisibleSection("bookAnApartment");
    };

    const theme = createTheme({
        palette: {
            primary: {
                main: '#302f2f',
            }
        },
    });

    return (
        <>
            <div id='container-apart1'>
                <Lazy type='slide' delay={150}  timeout={1000} direction='left' className='slide-container'>
                    <div className="frst">
                        <div className="snd">
                                <div id='apart'>
                                    <Navbar title={translate("rent_an_apartment")} previousPage="index" varaint={VARIANT.apartment} />
                                    <CarouselCard
                                        variant={VARIANT.secondary}
                                        imageSrc={selectedAppartment?.imageSrc}
                                        price={selectedAppartment?.price}
                                        address={selectedAppartment?.address}
                                    />
                                    <div>
                                        <Typography color='#302f2f' sx={{ fontSize: "16px", fontWeight: 'bold' }}>
                                            {selectedAppartment?.name}
                                        </Typography>
                                        <Typography color='#302f2f' sx={{ fontSize: "15px", fontWeight: 'regular' }}>
                                            {selectedAppartment?.address}
                                        </Typography>

                                        <Divider sx={{ marginTop: '20px', marginBottom: '20px' }} />
                                        <Typography color='#302f2f' sx={{ fontSize: "16px", fontWeight: 'bold' }}>
                                            {translate("what_this_place_offers")}
                                        </Typography>
                                        <List
                                            sx={{ width: '100%', bgcolor: 'background.paper', backgroundColor: 'white', borderRadius: '5px', boxShadow: '0px 0px 4px #c5c2c2' }}
                                        >

                                            {selectedAppartment?.offers &&
                                                selectedAppartment?.offers.nextflix &&
                                                (<ListItemButton>
                                                    <ListItemIcon sx={{ minWidth: '25px' }}>
                                                        <VrpanoIcon sx={{ fontSize: "20px", color: '#302f2f' }} />
                                                    </ListItemIcon>
                                                    <ListItemText secondary={<Typography sx={{ fontSize: "16px", fontWeight: 'regular' }}>{translate("courtyard_view")}</Typography>} />
                                                </ListItemButton>)
                                            }
                                            {selectedAppartment?.offers &&
                                                selectedAppartment?.offers.courtYardView &&
                                                (<ListItemButton>
                                                    <ListItemIcon sx={{ minWidth: '25px' }}>
                                                        <LiveTvIcon sx={{ fontSize: "20px", color: '#302f2f' }} />
                                                    </ListItemIcon>
                                                    <ListItemText secondary={<Typography sx={{ fontSize: "16px", fontWeight: 'regular' }}>{translate("netflix")}</Typography>} />
                                                </ListItemButton>)
                                            }

                                            {selectedAppartment?.offers &&
                                                selectedAppartment?.offers.wifi &&
                                                (<ListItemButton>
                                                    <ListItemIcon sx={{ minWidth: '25px' }}>
                                                        <WifiIcon sx={{ fontSize: "20px", color: '#302f2f' }} />
                                                    </ListItemIcon>
                                                    <ListItemText secondary={<Typography sx={{ fontSize: "16px", fontWeight: 'regular' }}>{translate("wifi")}</Typography>} />
                                                </ListItemButton>)
                                            }{selectedAppartment?.offers &&
                                                selectedAppartment?.offers.parking &&
                                                (<ListItemButton>
                                                    <ListItemIcon sx={{ minWidth: '25px' }}>
                                                        <LocalParkingIcon sx={{ fontSize: "20px", color: '#302f2f' }} />
                                                    </ListItemIcon>
                                                    <ListItemText secondary={<Typography sx={{ fontSize: "16px", fontWeight: 'regular' }}>{translate("free_parking_on_premises")}</Typography>} />
                                                </ListItemButton>)
                                            }{selectedAppartment?.offers &&
                                                (selectedAppartment?.offers.dedicatedWokspace && (<ListItemButton>
                                                    <ListItemIcon sx={{ minWidth: '25px' }}>
                                                        <WorkspacesIcon sx={{ fontSize: "20px", color: '#302f2f' }} />
                                                    </ListItemIcon>
                                                    <ListItemText secondary={<Typography sx={{ fontSize: "16px", fontWeight: 'regular' }}>{translate("dedicated_workspace")}</Typography>} />
                                                </ListItemButton>))
                                            }

                                        </List>

                                        <Divider sx={{ marginTop: '20px', marginBottom: '20px' }} />
                                        <ThemeProvider theme={theme}>
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            checked={selectRange}
                                                            onChange={handleSelectDateRange}
                                                        />
                                                    }
                                                    label={<Typography color='#302f2f' sx={{ fontSize: '16px', fontWeight: '500' }}>{translate("select_a_range_of_date")}</Typography>}
                                                />
                                            </FormGroup>
                                        </ThemeProvider>
                                        <>
                                            {loadDisableDates &&
                                            <Skeleton
                                            animation='pulse'
                                            sx={{ bgcolor: 'grey.500', width: '100%', height: '250px', borderRadius: '10px', marginBottom: '10px' }}
                                            variant="rectangular"
                                            />
                                            }
                                            {!loadDisableDates && (
                                                <Calendar
                                                locale={calendarLang}
                                                minDate={new Date()}
                                                onChange={getDate}
                                                sort
                                                value={selectedDates}
                                                range={selectRange}
                                                multiple
                                                className="dark"
                                                mapDays={({ date }) => (
                                                    {
                                                        disabled: datesToBeDisabled?.includes(
                                                            date.toDate().toISOString().split("T")[0]
                                                        ),
                                                    })}
                                            />
                                            )}
                                        </>
                                        <form onSubmit={() => onSubmit()}>
                                            <Box mt={2}>
                                                {selectedDates.length > 1 && (
                                                    <Grid container spacing={4} mt={0}>
                                                        <Grid item xs={6}>
                                                            <Typography variant="subtitle1" sx={{ fontSize: '16px', fontWeight: '500' }}>
                                                                {translate("start")}
                                                            </Typography>
                                                            <Paper sx={inputStyles}>
                                                                <IconButton sx={{ p: "5px" }}>
                                                                    <CalendarMonthIcon />
                                                                </IconButton>

                                                                <InputBase
                                                                    sx={{ ml: 1, flex: 1, fontSize: '14px', fontWeight: '300' }}
                                                                    placeholder={selectedDates[0].toLocaleDateString(
                                                                        "en-GB"
                                                                    )}
                                                                    disabled
                                                                />
                                                            </Paper>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography variant="subtitle1" sx={{ fontSize: '16px', fontWeight: '500' }}>
                                                                {translate("end")}
                                                            </Typography>
                                                            <Paper sx={inputStyles}>
                                                                <IconButton sx={{ p: "5px" }}>
                                                                    <CalendarMonthIcon />
                                                                </IconButton>
                                                                <InputBase
                                                                    sx={{ ml: 1, flex: 1, fontSize: '14px', fontWeight: '300' }}
                                                                    placeholder={selectedDates[
                                                                        selectedDates.length - 1
                                                                    ].toLocaleDateString("en-GB")}
                                                                    disabled
                                                                />
                                                            </Paper>
                                                        </Grid>
                                                    </Grid>
                                                )}
                                                <Grid container spacing={4} mt={1} mb={2} className="totalContainer">
                                                    <Grid item xs={6} className="total" alignSelf="center">
                                                        <Typography color='#302f2f' sx={{ fontSize: '16px', fontWeight: '500' }}>
                                                            {translate("total_for")} {selectedDates.length}{" "}
                                                            {translate("day")}(s)
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6} className="totalAmount">
                                                        <Typography color='#302f2f' sx={{ fontSize: '16px', fontWeight: '500' }}>
                                                            <span className="curency">XAF</span>{" "}
                                                            {new Intl.NumberFormat().format(price)}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>

                                            </Box>
                                            <Stack direction={"row"} columnGap={2} mb={2} alignItems="end">
                                                <Button
                                                    variant="text"
                                                    onClick={resetForm}
                                                    sx={{
                                                        height: "50px",
                                                        background: "#f2f2f2",
                                                        "&:hover": { background: "#f2f2f2!important" },
                                                    }}
                                                >
                                                    <Typography color={"black"} fontSize="16px">
                                                        <RestartAltIcon sx={{ color: "black", fontSize: "34px" }} />
                                                    </Typography>
                                                </Button>
                                                <Button
                                                    fullWidth
                                                    variant="contained"
                                                    className="primary"
                                                    endIcon={<ArrowForwardIcon sx={{ color: 'white' }} />}
                                                    onClick={() => onSubmit()}
                                                    sx={{
                                                        height: 50,
                                                        borderRadius: "6px",
                                                        textTransform: "initial",
                                                        fontSize: "16px",
                                                        "&:disabled": {
                                                            "-webkit-text-fill-color": "#c6c6c6 !important",
                                                        },
                                                        marginTop: "10px",
                                                        flexGrow: 1,
                                                    }}
                                                    disabled={
                                                        selectedDates.length < 1 && selectedDates.length < 31
                                                    }
                                                >
                                                    {translate("next")}

                                                </Button>
                                            </Stack>
                                        </form>
                                        <Modal
                                            open={limitNumberOfDays}
                                            onClose={() => {
                                                setLimitNumberOfDays(false);
                                                setSelectedDates([]);
                                            }}
                                            aria-labelledby="modal-modal-title"
                                            aria-describedby="modal-modal-description"
                                        >
                                            <Box sx={{ ...style, position: "relative" }}>
                                                <Close
                                                    onClick={() => {
                                                        setLimitNumberOfDays(false);
                                                        setSelectedDates([]);
                                                    }}
                                                    sx={{ position: "absolute", right: "10px", top: "15px" }}
                                                />
                                                <Typography
                                                    color="orange"
                                                    id="modal-modal-description"
                                                    sx={{ mt: 2 }}
                                                >
                                                    {translate("limit_number_of_days")}
                                                </Typography>
                                            </Box>
                                        </Modal>
                                    </div>
                                </div>
                        </div>
                    </div>
                </Lazy>
            </div>
        </>
    );
};

export default CalculateAparmentPriceForm;
