import React, { useContext, useState } from "react";
import Box from '@mui/material/Box';
import {
    Button,
    IconButton,
    InputAdornment,
    InputBase,
    Paper,
    Typography,
} from "@mui/material";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import Navbar from "../../../components/navbar";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { OrderAppartmentContext } from "../../../context/OrderContext";
import { ContentContext } from "../../layout";
import Lazy from "../../lazyAnimation";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';




const enum VARIANT {
    primary = "primary",
    secondary = "secondary",
    car = "car",
    apartment = "apartment"
}

const inputStyles = {
    p: "2px 4px",
    display: "flex",
    alignItems: "center",
    background: "#f2f2f2",
    boxShadow: "none",
    borderRadius: "8px",
};

const BookAnApartmentForm = () => {
    const { translate } = useContext(ContentContext);
    const {
        userInfo,
        setUserInfo,
        setVisibleSection,
    } = useContext(OrderAppartmentContext);
    const schema = yup.object().shape({
        name: yup.string().required(`${translate("required_field")}`),
        phone: yup
            .number()
            .required(`${translate("required_field")}`)
            .test(
                "len",
                `${translate("the_field_must_contain_exactly_nine_digits")}`,
                (val) => val?.toString()?.length === 9
            )
            .typeError(`${translate("insert_numbers_only")}`)
    });

    const {
        register,
        handleSubmit,
        formState,
        control,
        setValue,
        getValues,
        reset,
    } = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
        defaultValues: {
            name: userInfo.name,
            phone: userInfo.phoneNumber
        },
    });

    const { errors, isSubmitting } = formState;

    const onSubmit = async (formData: Object) => {
        setVisibleSection("summary");
    };

    return (
        <>
            <div id='container-apart'>
                <Lazy type='slide' delay={150}  timeout={1000} direction='left' className='slide-container'>
                    <div className="frst">
                        <div className="snd">
                                <Box id='apart' className="form-confirm"
                                    sx={{
                                        maxWidth: 400,
                                        margin: "0 auto",
                                        display: "flex",
                                        flexDirection: "column",
                                        p: 1,
                                    }}
                                >
                                    <Navbar
                                        title={`${translate("confirm_order")}`}
                                        previousPage="calculatePrice"
                                        varaint={VARIANT.apartment}
                                    />
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <Box display="flex" flexDirection="column" gap={2}>
                                            <Box flexBasis={10}>
                                                <Paper
                                                    sx={{
                                                        border: errors.name ? "1px solid red" : null,
                                                        ...inputStyles,
                                                    }}
                                                >
                                                    <IconButton sx={{ p: "10px" }} aria-label="menu">
                                                        <PersonOutlineIcon />
                                                    </IconButton>
                                                    <Controller
                                                        control={control}
                                                        render={({ field }) => (
                                                            <InputBase
                                                                sx={{ ml: 1, flex: 1, fontSize: '14px', fontWeight: '300' }}
                                                                placeholder={translate("name")}
                                                                inputProps={{ "aria-label": "name" }}
                                                                {...register("name")}
                                                                onChange={(e) => {
                                                                    setUserInfo({ ...userInfo, name: e.target.value });
                                                                    setValue("name", e.target.value, {
                                                                        shouldValidate: true,
                                                                    });
                                                                }}
                                                                value={getValues().name}
                                                            />
                                                        )}
                                                        name="name"
                                                    ></Controller>
                                                </Paper>
                                                {errors.name && (
                                                    <Typography
                                                        variant="caption"
                                                        color="red"
                                                        mt={1}
                                                        component="div"
                                                    >
                                                        {errors?.name?.message}
                                                    </Typography>
                                                )}
                                            </Box>

                                            <Box>
                                                <Paper
                                                    sx={{
                                                        border: errors.phone ? "1px solid red" : null,
                                                        ...inputStyles,
                                                    }}
                                                >
                                                    <IconButton sx={{ p: "10px" }} aria-label="menu">
                                                        <PhoneAndroidIcon />
                                                    </IconButton>
                                                    <Controller
                                                        control={control}
                                                        render={({ field }) => (
                                                            <InputBase
                                                                sx={{ ml: 1, flex: 1, fontSize: '14px', fontWeight: '300' }}
                                                                placeholder={translate("phone_number")}
                                                                inputProps={{ "aria-label": "phone number",  "aria-autocomplete": "both" }}
                                                                startAdornment={
                                                                    <InputAdornment position="start">
                                                                        <Typography sx={{ fontSize: '14px', fontWeight: '300' }}>+237</Typography>
                                                                    </InputAdornment>
                                                                }
                                                                {...register("phone")}
                                                                onKeyDown={(e: any) => {
                                                                    var key = e.keyCode || e.charCode;
                                                                    if (key === 8 || key === 46) {
                                                                        if (getValues().phone.toString().length === 1) {
                                                                            setUserInfo({
                                                                                ...userInfo,
                                                                                phoneNumber: "",
                                                                            });
                                                                            setValue("phone", "", {
                                                                                shouldValidate: true,
                                                                            });
                                                                        }
                                                                        return true;
                                                                    } else {
                                                                        if (
                                                                            !/[0-9]/.test(e.key) ||
                                                                            getValues().phone.toString().length === 9
                                                                        ) {
                                                                            e.preventDefault();
                                                                        }
                                                                    }
                                                                }}
                                                                onChange={(e) => {
                                                                    if (!/[0-9]/.test(e.target.value)) {
                                                                        e.preventDefault();
                                                                    } else {
                                                                        setUserInfo({
                                                                            ...userInfo,
                                                                            phoneNumber: e.target.value as unknown as number,
                                                                        });
                                                                        setValue("phone", e.target.value, {
                                                                            shouldValidate: true,
                                                                        });
                                                                    }
                                                                }}
                                                                value={getValues().phone || ""}
                                                            />
                                                        )}
                                                        name="phone"
                                                    ></Controller>
                                                </Paper>
                                                {errors.phone && (
                                                    <Typography
                                                        variant="caption"
                                                        color="red"
                                                        mt={1}
                                                        component="div"
                                                    >
                                                        {errors?.phone?.message}
                                                    </Typography>
                                                )}
                                            </Box>

                                            <Button
                                                fullWidth
                                                variant="contained"
                                                className="primary"
                                                endIcon={<ArrowForwardIcon sx={{ color: 'white' }} />}
                                                sx={{
                                                    marginTop: 2,
                                                    height: 50,
                                                    borderRadius: "6px",
                                                    textTransform: "initial",
                                                    fontSize: "16px",
                                                    "&:disabled": {
                                                        "WebkitTextFillColor": "#c6c6c6 !important",
                                                    },
                                                }}
                                                type="submit"
                                                disabled={isSubmitting}
                                            >
                                                {translate("next")}
                                            </Button>
                                        </Box>
                                    </form>
                                </Box>
                        </div>
                    </div>
                </Lazy>
            </div>
        </>
    );
};

export default BookAnApartmentForm;
